import { Popup } from 'Lib/popup/popup';
import { eventSender } from 'Lib/event-sender/event-sender';
import { getW } from 'Lib/w/w';
import { eksmoBP } from 'Lib/bp/bp';
import Lottie from 'lottie-web';

const elPashalka = document.querySelectorAll('.pashalka:not(.pashalka_not-active)');
const elPashalkaActive = elPashalka.length > 1 ? elPashalka[Math.floor(Math.random() * elPashalka.length)] : elPashalka[0];
const cPashalkaAnimationActive = 'pashalka_active';
const cPashalkaAnimationActivePopup = elPashalkaActive?.querySelector('.pashalka__popup');

$(window).on('scroll', $.throttle(700, () => {
    if (window.pageYOffset > 50 && window.pageYOffset < 4000) {
        if (!elPashalkaActive?.classList.contains(cPashalkaAnimationActive)) {
            elPashalkaActive?.classList.add(cPashalkaAnimationActive);
        }
    } else if (elPashalkaActive?.classList.contains(cPashalkaAnimationActive)) {
        elPashalkaActive?.classList.remove(cPashalkaAnimationActive);
        elPashalkaActive?.classList.remove('pashalka_animation-img2-active');
    }

    if (elPashalkaActive?.classList.contains(cPashalkaAnimationActive)
        && elPashalkaActive?.classList.contains('pashalka_animation-img2')
        && getW() < eksmoBP.smMax) {
        setTimeout(() => {
            elPashalkaActive?.classList.add('pashalka_animation-img2-active');
        }, 3000);
    }
}));

elPashalkaActive?.addEventListener('click', (e) => {
    const target = e.target;

    if (target.classList.contains('pashalka__item-slide') || target.closest('.pashalka__item-slide') || target.closest('.pashalka__item-cont')) {
        eventSender.customDL({
            event: 'eksmo',
            eventAction: 'click',
            eventCategory: elPashalkaActive.getAttribute('data-name'),
            eventLabel: window.location.href,
        });

        eventSender.customYM({
            'click': {
                'click_secret_page': {
                    [elPashalkaActive.getAttribute('data-name')]: {
                        'link': `[${window.location.href}]`,
                        'title': `[${document.querySelector('title').textContent.trim()}]`,
                    },
                },
            },
        });
    }
});

if (cPashalkaAnimationActivePopup) {
    const popup = new Popup('popup', cPashalkaAnimationActivePopup);

    document.querySelector('.pashalka_popup-btn').addEventListener('click', (e) => {
        popup.open();
    });
}

function setAnimation(selector, path) {
    Lottie.loadAnimation({
        container: selector, // контейнер для анимации
        renderer: 'svg', // тип рендерера (может быть 'svg', 'canvas' или 'html')
        loop: true, // зацикливание анимации
        autoplay: true, // автоматический запуск анимации
        path, // путь к вашему JSON-файлу с анимацией
    });
}

document.querySelectorAll('.pashalka__animation').forEach((item) => {
    setAnimation(item, `${window.URLBuild}/images/site/main/img/pashalka/eyes/${item.dataset?.name}.json`);
});

// document.querySelector('body').addEventListener('mousemove', eyeball);
//
// function eyeball(e) {
//     const eyes = document.querySelector('.pashalka_eyes .pashalka__item-cont');
//     const x = eyes.getBoundingClientRect().x + eyes.clientWidth / 2;
//     const y = eyes.getBoundingClientRect().y + eyes.clientHeight / 2;
//     const radian = Math.atan2(e.pageX - x, e.pageY - y - window.pageYOffset);
//     const rotate = radian * (180 / Math.PI) * -1 + 270;
//
//     eyes.style.transform = `rotate(${rotate}deg)`;
// }
